
import { defineComponent, PropType, ref, computed, nextTick, watch } from "vue";
import Vue3DraggableResizable from "vue3-draggable-resizable";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import BaseCheckBox from "@/components/atomics/BaseCheckBox.vue";
import { SignerI } from "@/models/Signer";
import { useVModel, syncRef, useDebounceFn } from "@vueuse/core";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";
import {
  checkfieldType,
  textfieldType,
  defaultTextfieldMinWidth,
  defaultCheckfieldMinWidth,
} from "@/constants/inputsVars";
import useInputVars from "@/lib/utility/useInputVars";

export default defineComponent({
  name: "TextInputDraggableResizable",
  components: {
    Dropdown,
    ToggleCheckbox,
    "vue-draggable-resizable": Vue3DraggableResizable,
    BaseCheckBox,
  },
  props: {
    type: { type: Number, default: 1 },
    isOptionalText: { type: Boolean, default: false },
    isChecked: { type: Boolean, default: false },
    isCheckedByDefault: { type: Boolean, default: false },
    isStamp: { type: Boolean, default: false },
    id: { type: String, default: "" },
    x: { type: Number, default: 0 },
    y: { type: Number, default: 0 },
    w: { type: Number, default: 200 },
    h: { type: Number, default: 50 },
    initPadding: { type: Number, default: 0 },
    minHeight: { type: Number, default: 0 },
    signers: {
      type: Array as PropType<Array<Record<string, SignerI>>>,
      default: () => [],
    },
    signerID: { type: String, default: undefined },
    signerOrder: { type: Number, default: 0 },
    fontSize: { type: String, default: "10" },
    stampType: { type: Boolean, default: true },
    isEdit: { type: Boolean, default: false },
    canvasWidth: { type: Number, required: true },
    canvasHeight: { type: Number, required: true },
    text: { type: String, default: "" },
    scale: { type: Number, default: 1.0 },
  },
  emits: [
    "onRemoveItem",
    "onCopyItem",
    "update:signerID",
    "update:signerOrder",
    "update:isCheckedByDefault",
    "update:isChecked",
    "update:x",
    "update:y",
    "update:w",
    "update:h",
    "update:text",
    "update:isOptionalText",
    "update:fontSize",
  ],
  setup(props, { emit }) {
    const { isMobile } = useBreakpoint();
    const { fontSizes, getFontSizeMobile } = useInputVars();

    const container = ref<HTMLElement>();
    const textarea = ref<HTMLTextAreaElement>();
    const measuring = ref<HTMLDivElement>();

    const isActive = ref(false);

    const fieldX = useVModel(props, "x", emit);
    const fieldY = useVModel(props, "y", emit);
    const fieldW = useVModel(props, "w", emit);
    const fieldH = useVModel(props, "h", emit);
    const fontSize = useVModel(props, "fontSize", emit);

    const minWidth = computed(() => {
      const minW =
        props.type === textfieldType
          ? defaultTextfieldMinWidth
          : defaultCheckfieldMinWidth;

      return (isMobile.value ? minW - 40 : minW) * props.scale;
    });
    const fontSizeDesktop = computed(
      () => Number(fontSize.value) * props.scale
    );
    const fontSizeMob = computed(() => getFontSizeMobile(fontSize.value));

    const checkBoxText = useVModel(props, "text", emit);
    const localIsOptionalText = useVModel(props, "isOptionalText", emit);
    const isDefaultChecked = useVModel(props, "isCheckedByDefault", emit);
    const localIsChecked = useVModel(props, "isChecked", emit);

    const dragging = (position: { x: number; y: number }) => {
      fieldX.value = position.x > 0 ? position.x : 0;
      fieldY.value = position.y > 0 ? position.y : 0;
    };

    const debouncedDragging = useDebounceFn(dragging, 50);

    const dragEnd = ({ x = 0, y = 0 }) => {
      fieldX.value = x;
      fieldY.value = y;
    };

    async function input(onEnter = false, event: InputEvent | null = null) {
      if (!measuring.value) return;
      if (!textarea.value) return;
      if (!container.value) return;

      const offsetLeft = container.value.offsetLeft;

      measuring.value.innerText = checkBoxText.value;

      if (event && event.isComposing) {
        measuring.value.innerText = checkBoxText.value + event.data;
      }

      textarea.value.style.height = "1em";

      if (onEnter) {
        const bottom =
          fieldY.value + textarea.value.scrollHeight + 20 * props.scale;
        if (bottom >= props.canvasHeight) {
          fieldH.value = props.canvasHeight - fieldY.value - 1;
        } else {
          fieldH.value = Math.max(
            textarea.value.scrollHeight + 20 * props.scale,
            props.type === checkfieldType ? 40 * props.scale : 0
          );
        }
      } else {
        const right =
          fieldX.value +
          measuring.value.scrollWidth +
          offsetLeft +
          25 * props.scale;
        if (right >= props.canvasWidth) {
          fieldW.value = props.canvasWidth - fieldX.value - 1;
        } else {
          fieldW.value =
            measuring.value.scrollWidth + 5 + offsetLeft + 25 * props.scale;
          textarea.value.style.minWidth =
            measuring.value.scrollWidth + 5 + "px";
        }

        await nextTick(() => {
          if (!textarea.value) return;
          const bottom =
            fieldY.value + textarea.value.scrollHeight + 20 * props.scale;
          if (bottom >= props.canvasHeight) {
            fieldH.value = props.canvasHeight - fieldY.value - 1;
          } else {
            fieldH.value = Math.max(
              textarea.value.scrollHeight + 20 * props.scale,
              props.type === checkfieldType ? 40 * props.scale : 0
            );
          }
        });
      }
      textarea.value.style.height = "100%";
    }

    return {
      container,
      measuring,
      textarea,

      checkfieldType,

      isMobile,
      isActive,
      fieldX,
      fieldY,
      fieldW,
      fieldH,
      checkBoxText,
      localIsOptionalText,
      isDefaultChecked,
      localIsChecked,
      minWidth,

      fontSizes,
      fz: fontSize,
      fontSizeDesktop,
      fontSizeMob,

      debouncedDragging,
      dragEnd,
      input,
    };
  },
});
