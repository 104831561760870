import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _mergeProps({
    class: "toggle-wrapper",
    role: "checkbox"
  }, _toHandlers(!_ctx.disabled ? { click: _ctx.toggle } : {})), [
    _createElementVNode("span", {
      class: _normalizeClass(["toggle-background", _ctx.backgroundStyles])
    }, null, 2),
    _createElementVNode("span", {
      class: "toggle-indicator",
      style: _normalizeStyle(_ctx.indicatorStyles)
    }, null, 4)
  ], 16))
}