import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74358a63"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "label-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["label", { disabled: _ctx.$attrs.disabled == '' }])
  }, [
    _withDirectives(_createElementVNode("input", _mergeProps({
      type: "checkbox",
      class: "checkbox mr-1"
    }, _ctx.$attrs, {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event))
    }), null, 16), [
      [_vModelCheckbox, _ctx.localValue]
    ]),
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}