
import { defineComponent, PropType } from "vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import FileDropdown from "@/components/atomics/FileDropdown.vue";
import { DraggableContainer } from "vue3-draggable-resizable";
import TextInputDraggableResizable from "@/components/atomics/TextInputDraggableResizable.vue";
import PdfPreviewVars from "@/components/atomics/pdf-viewer/PdfPreviewVars.vue";
import useInputVars from "@/lib/utility/useInputVars";
import { FileInfo } from "@/models/Template";
import { SignerI } from "@/models/Signer";
import { useVModel } from "@vueuse/core";
import { useMounted } from "@vueuse/core";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import DropdownTrigger from "@/components/atomics/dropdown/DropdownTrigger.vue";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";

export default defineComponent({
  name: "TemplateVars",
  components: {
    TextButton,
    FileDropdown,
    DraggableContainer,
    TextInputDraggableResizable,
    PdfPreviewVars,
    Dropdown,
    DropdownTrigger,
  },
  props: {
    files: {
      type: Array as PropType<Array<FileInfo>>,
      required: true,
    },
    signers: {
      type: Array as PropType<Array<Record<string, SignerI>>>,
      required: true,
    },
  },
  emits: ["update:files"],
  beforeRouteEnter(to, from, next) {
    if (
      from.name == "TemplateSummary" ||
      from.name == "TemplateSigner" ||
      to.params.id
    )
      next();
    else next({ name: "TemplateFileAndInfo" });
  },
  setup(props, { emit }) {
    const localFiles = useVModel(props, "files", emit);
    const isMounted = useMounted();
    const { isMobile } = useBreakpoint();

    const onPageScaled = (file: FileInfo, scale: number) => {
      if (file.scale && file.scale != 1) return;

      file.inputs.forEach((input) => {
        input.w = Math.floor(input.w * scale);
        input.h = Math.floor(input.h * scale);
        input.x = input.x * scale;
        input.y = input.y * scale;
        input.padding = input.padding * scale;
        input.minHeight = input.minHeight * scale;
      });
      file.scale = scale;
    };

    const handleDrag = (e: DragEvent) => {
      if (!e.dataTransfer) return;
      const fileId = e.dataTransfer.getData("fileId");
      const file = localFiles.value.find((item) => item.id === fileId);
      if (!file) return;
      inputVars.addVarFromDrag(e, file, "round", 21);
    };

    const inputVars = useInputVars();

    const getNextPage = (currentPage: number, pageNums: number) => {
      if (currentPage < pageNums) return currentPage + 1;
      return currentPage;
    };

    const getPrevPage = (currentPage: number) => {
      if (currentPage > 1) return currentPage - 1;
      return currentPage;
    };

    const setPage = (currentPage: any, pageNums: number) => {
      if (currentPage <= 0) {
        currentPage = 1;
      } else if (currentPage >= pageNums) {
        currentPage = pageNums;
      } else {
        currentPage = parseInt(currentPage);
      }
      return currentPage;
    };

    return {
      ...inputVars,
      localFiles,
      onPageScaled,
      handleDrag,
      getNextPage,
      getPrevPage,
      setPage,
      isMounted,
      isMobile,
    };
  },
});
