
import { defineComponent, ref, PropType } from "vue";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";

export default defineComponent({
  name: "FileTabs",
  components: {
    Dropdown,
  },
  props: {
    isBorder: { type: Boolean, default: true },
    tabNames: { type: Array as PropType<Array<string>>, default: () => [] },
    isTerms: { type: Boolean, default: false },
    teleportTabsHeaderTo: { type: String, default: "body" },
  },
  emits: ["onChangeTab"],
  setup(props) {
    const selectedIndex = ref(0);

    const selectTab = (i: string) => {
      selectedIndex.value = props.tabNames.indexOf(i);
    };

    return { selectedIndex, selectTab };
  },
});
