
import { defineComponent, ref } from "vue";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";

export default defineComponent({
  name: "PdfPreviewVars",
  components: {
    PdfViewer,
  },
  props: {
    filePath: { type: String, default: "" },
    page: { type: Number, default: 1 },
    isPadding: { type: Boolean, default: false },
  },
  emits: ["onReady", "pageScaled", "pageSized", "update:page"],

  setup(_, { emit }) {
    const isPageReady = ref(false);

    const pageLoaded = (page: number) => {
      emit("update:page", page);
      isPageReady.value = true;
    };
    return {
      isPageReady,
      pageLoaded,
    };
  },
});
