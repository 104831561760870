import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fb60ca2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "input-wrapper",
  ref: "container"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  key: 1,
  class: "draggable-input-area-circle"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 2,
  class: "options-wrapper"
}
const _hoisted_8 = {
  key: 0,
  class: "toggle-checkbox-container w-250"
}
const _hoisted_9 = {
  key: 0,
  class: "toggle-checkbox-field"
}
const _hoisted_10 = { class: "toggle-checkbox-text" }
const _hoisted_11 = {
  key: 1,
  class: "thin-divider"
}
const _hoisted_12 = {
  key: 2,
  class: "toggle-checkbox-field"
}
const _hoisted_13 = { class: "toggle-checkbox-text" }
const _hoisted_14 = {
  key: 3,
  class: "thin-divider"
}
const _hoisted_15 = { class: "selection-field" }
const _hoisted_16 = { class: "toggle-checkbox-text no-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckBox = _resolveComponent("BaseCheckBox")!
  const _component_ToggleCheckbox = _resolveComponent("ToggleCheckbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_vue_draggable_resizable = _resolveComponent("vue-draggable-resizable")!

  return (_openBlock(), _createBlock(_component_vue_draggable_resizable, {
    class: _normalizeClass([{
      'draggable-resizable': !_ctx.isStamp,
      'draggable-resizable-stamp': _ctx.isStamp,
    }, "input-draggable"]),
    "class-name-handle": "my-handle-class",
    "class-name-active": "my-active-class upper",
    key: _ctx.id,
    parent: true,
    x: _ctx.fieldX,
    y: _ctx.fieldY,
    w: _ctx.isStamp ? _ctx.fieldW + _ctx.initPadding * 2 : _ctx.fieldW,
    h: _ctx.isStamp ? _ctx.fieldH + _ctx.initPadding * 2 : _ctx.fieldH,
    "min-w": !_ctx.isStamp ? _ctx.minWidth : _ctx.fieldW,
    "min-h": !_ctx.isStamp ? _ctx.minHeight : 0,
    resizable: false,
    active: _ctx.isActive,
    "onUpdate:active": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isActive) = $event)),
    "lock-aspect-ratio": _ctx.isStamp,
    onDragging: _cache[10] || (_cache[10] = ($event: any) => (_ctx.debouncedDragging($event))),
    onDragEnd: _ctx.dragEnd
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "remove-icon-mobile",
        src: 
        _ctx.isMobile
          ? require('icons/delete-cross.svg')
          : require('icons/remove_icon_cross_white.svg')
      ,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onRemoveItem')))
      }, null, 8, _hoisted_1),
      _createElementVNode("img", {
        class: "copy-icon-mobile",
        src: 
        _ctx.isMobile
          ? require('icons/content_copy_icon_mobile.svg')
          : require('icons/content_copy_icon.svg')
      ,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onCopyItem')))
      }, null, 8, _hoisted_2),
      (!_ctx.isStamp)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "draggable-input-area input",
            style: _normalizeStyle({ '--padding': 10 * _ctx.scale + 'px' })
          }, [
            (_ctx.type === 3)
              ? (_openBlock(), _createBlock(_component_BaseCheckBox, {
                  key: 0,
                  style: _normalizeStyle({
          minWidth: `${20 * _ctx.scale}px`,
          height: `${20 * _ctx.scale}px`,
        }),
                  modelValue: _ctx.localIsChecked,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localIsChecked) = $event))
                }, null, 8, ["style", "modelValue"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: "measuring",
                ref: "measuring",
                style: _normalizeStyle(
            '--fz:' + _ctx.fontSizeDesktop + 'px;--fz-mob:' + _ctx.fontSizeMob + 'px'
          )
              }, null, 4),
              _withDirectives(_createElementVNode("textarea", {
                class: "input-draggable-resizable",
                placeholder: _ctx.$t('template.vars.placeHolder'),
                style: _normalizeStyle(
            '--fz:' + _ctx.fontSizeDesktop + 'px;--fz-mob:' + _ctx.fontSizeMob + 'px'
          ),
                ref: "textarea",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkBoxText) = $event)),
                onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.input(false, $event as InputEvent))),
                maxlength: "1000",
                contenteditable: "true"
              }, null, 44, _hoisted_4), [
                [_vModelText, _ctx.checkBoxText]
              ])
            ], 512)
          ], 4))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "container-circle",
              style: _normalizeStyle({
          width: _ctx.fieldH + 'px',
          height: _ctx.fieldH + 'px',
        })
            }, [
              _createElementVNode("img", {
                class: "circle-draggable-resizable",
                alt: "stamp image",
                src: require('images/stamp-orange.svg')
              }, null, 8, _hoisted_6)
            ], 4)
          ])),
      (_ctx.isActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (!_ctx.isStamp)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_ctx.type === _ctx.checkfieldType)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_ToggleCheckbox, {
                          class: "toggle-checkbox",
                          checked: _ctx.isDefaultChecked,
                          "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDefaultChecked) = $event))
                        }, null, 8, ["checked"]),
                        _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t("template.vars.toggleButtonText1")), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.type === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11))
                    : _createCommentVNode("", true),
                  (_ctx.type === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createVNode(_component_ToggleCheckbox, {
                          class: "toggle-checkbox",
                          checked: _ctx.localIsOptionalText,
                          "onUpdate:checked": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localIsOptionalText) = $event))
                        }, null, 8, ["checked"]),
                        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("template.vars.toggleButtonText2")), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.type === 3)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t("template.vars.fontSize")), 1),
                    _createVNode(_component_Dropdown, {
                      class: "dropdown-fontsize dropdown",
                      options: _ctx.fontSizes,
                      "model-value": _ctx.fz,
                      "onUpdate:model-value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.fz) = $event))
                    }, null, 8, ["options", "model-value"])
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.signers.length != 0)
              ? (_openBlock(), _createBlock(_component_Dropdown, {
                  key: 1,
                  class: _normalizeClass(["dropdown w-250", {
          'dropdown-checkbox': _ctx.type === _ctx.checkfieldType,
        }]),
                  "item-text": "placeHolder",
                  "item-value": "id",
                  options: _ctx.signers,
                  "model-value": _ctx.signers[_ctx.signerOrder - 1],
                  "place-holder": _ctx.$t('template.vars.selectPlaceHolder'),
                  onOnSelectItem: _cache[8] || (_cache[8] = 
          (payload) => {
            _ctx.$emit('update:signerID', payload.id);
            _ctx.$emit('update:signerOrder', payload.order);
          }
        )
                }, null, 8, ["class", "options", "model-value", "place-holder"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "x", "y", "w", "h", "min-w", "min-h", "active", "lock-aspect-ratio", "onDragEnd"]))
}