
import { defineComponent, computed, reactive, toRefs, watch } from "vue";

export default defineComponent({
  name: "ToggleCheckbox",
  props: {
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isActBeforeToggle: { type: Boolean, default: false },
  },
  emits: ["onChange", "update:checked"],
  setup(props) {
    const state = reactive({
      checkbox: props.checked,
    });

    watch(
      () => props.checked,
      (newVal: boolean) => {
        state.checkbox = newVal;
      }
    );

    const backgroundStyles = computed(() => {
      return {
        "background-active": state.checkbox,
        "background-inactive": !state.checkbox,
        disabled: props.disabled,
      };
    });

    const indicatorStyles = computed(() => {
      return {
        transform: state.checkbox ? "translateX(15px)" : "translateX(0)",
      };
    });

    return {
      ...toRefs(state),
      backgroundStyles,
      indicatorStyles,
    };
  },
  methods: {
    toggle() {
      let toggleValue = !this.checkbox;
      if (!this.isActBeforeToggle) {
        this.checkbox = toggleValue;
      }
      this.$emit("onChange", toggleValue);
      this.$emit("update:checked", toggleValue);
    },
  },
});
