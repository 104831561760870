import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f9bd651"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "preview-area" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfViewer = _resolveComponent("PdfViewer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pdf-preview-var", { 'pt-2 pl-2 pb-2 pr-2': _ctx.isPadding }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PdfViewer, {
        src: _ctx.filePath,
        page: _ctx.page,
        scaleFitWidth: true,
        onPageNums: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onReady', $event))),
        onPageLoaded: _ctx.pageLoaded,
        onPageScaled: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('pageScaled', $event))),
        onPageSized: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('pageSized', $event)))
      }, {
        default: _withCtx(() => [
          (_ctx.isPageReady)
            ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["src", "page", "onPageLoaded"])
    ])
  ], 2))
}