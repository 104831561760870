
import { defineComponent, computed } from "vue";
import { useField } from "vee-validate";

export default defineComponent({
  name: "BaseCheckBox",
  components: {},
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    isField: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { value: fieldValue } = useField(props.name, "", {
      initialValue: props.modelValue,
    });

    const localValue = computed({
      get(): boolean {
        return props.modelValue;
      },

      set(value: boolean) {
        fieldValue.value = value;
        emit("update:modelValue", value);
      },
    });

    return {
      localValue,
    };
  },
});
