import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Teleport as _Teleport, createBlock as _createBlock, withCtx as _withCtx, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1218cabc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-header pl-3" }
const _hoisted_2 = { class: "left-header" }
const _hoisted_3 = ["onDragstart"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onDragstart"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["onDragstart"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "right-header" }
const _hoisted_10 = ["onUpdate:modelValue", "onInput"]
const _hoisted_11 = { class: "total-number" }
const _hoisted_12 = { class: "container-draggable-resizable" }
const _hoisted_13 = { class: "title-mobile" }
const _hoisted_14 = { class: "preview-control-mobile" }
const _hoisted_15 = ["onUpdate:modelValue"]
const _hoisted_16 = { class: "total-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DropdownTrigger = _resolveComponent("DropdownTrigger")!
  const _component_TextInputDraggableResizable = _resolveComponent("TextInputDraggableResizable")!
  const _component_DraggableContainer = _resolveComponent("DraggableContainer")!
  const _component_PdfPreviewVars = _resolveComponent("PdfPreviewVars")!
  const _component_FileDropdown = _resolveComponent("FileDropdown")!

  return (_ctx.isMounted)
    ? (_openBlock(), _createBlock(_component_FileDropdown, {
        key: 0,
        "teleport-tabs-header-to": ".left-top-part",
        "tab-names": _ctx.localFiles.map((file) => file.name)
      }, {
        default: _withCtx(({ selectedIndex, selectTab }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localFiles, (file, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: file.id
            }, [
              (selectedIndex == index)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_openBlock(), _createBlock(_Teleport, { to: ".left-top-part" }, [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("div", {
                            class: "dragable-button",
                            draggable: "true",
                            onDragstart: ($event: any) => (_ctx.dragStamp($event.dataTransfer, file))
                          }, [
                            _createElementVNode("img", {
                              src: require('icons/drag-grey.svg')
                            }, null, 8, _hoisted_4),
                            _createVNode(_component_TextButton, {
                              onOnClick: ($event: any) => (_ctx.addStamp(file, file.currentPage)),
                              text: _ctx.$t('template.vars.addStamp'),
                              icon: require('icons/add-stamp.svg')
                            }, null, 8, ["onOnClick", "text", "icon"])
                          ], 40, _hoisted_3),
                          _createElementVNode("div", {
                            class: "dragable-button",
                            draggable: "true",
                            onDragstart: ($event: any) => (_ctx.dragText($event.dataTransfer, file))
                          }, [
                            _createElementVNode("img", {
                              src: require('icons/drag-grey.svg')
                            }, null, 8, _hoisted_6),
                            _createVNode(_component_TextButton, {
                              onOnClick: ($event: any) => (_ctx.addText(file, false, file.currentPage)),
                              text: _ctx.$t('template.vars.addText'),
                              icon: require('icons/add-text.svg')
                            }, null, 8, ["onOnClick", "text", "icon"])
                          ], 40, _hoisted_5),
                          _createElementVNode("div", {
                            class: "dragable-button",
                            draggable: "true",
                            onDragstart: ($event: any) => (_ctx.dragCheckbox($event.dataTransfer, file))
                          }, [
                            _createElementVNode("img", {
                              src: require('icons/drag-grey.svg')
                            }, null, 8, _hoisted_8),
                            _createVNode(_component_TextButton, {
                              onOnClick: ($event: any) => (_ctx.addText(file, true, file.currentPage)),
                              text: _ctx.$t('template.vars.addCheckBox'),
                              icon: require('icons/add-checkbox.svg')
                            }, null, 8, ["onOnClick", "text", "icon"])
                          ], 40, _hoisted_7)
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _withDirectives(_createElementVNode("input", {
                            type: "number",
                            class: "jump-page",
                            "onUpdate:modelValue": ($event: any) => ((file.currentPage) = $event),
                            onInput: ($event: any) => (
                  file.currentPage = _ctx.setPage(file.currentPage, file.pageNums)
                )
                          }, null, 40, _hoisted_10), [
                            [_vModelText, file.currentPage]
                          ]),
                          _createElementVNode("p", _hoisted_11, " / " + _toDisplayString(file.pageNums), 1),
                          _createVNode(_component_TextButton, {
                            class: "prev-page",
                            icon: require('icons/prev-arrow.svg'),
                            onOnClick: ($event: any) => (file.currentPage = _ctx.getPrevPage(file.currentPage)),
                            "disabled-icon": require('icons/prev-arrow-grey.svg'),
                            enabled: file.currentPage >= 2
                          }, null, 8, ["icon", "onOnClick", "disabled-icon", "enabled"]),
                          _createVNode(_component_TextButton, {
                            class: "next-page",
                            "right-icon": require('icons/next-arrow.svg'),
                            onOnClick: ($event: any) => (
                  file.currentPage = _ctx.getNextPage(
                    file.currentPage,
                    file.pageNums
                  )
                ),
                            "disabled-icon": require('icons/next-arrow-grey.svg'),
                            enabled: file.currentPage != file.pageNums
                          }, null, 8, ["right-icon", "onOnClick", "disabled-icon", "enabled"])
                        ])
                      ])
                    ])),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_Dropdown, {
                          options: _ctx.localFiles,
                          "item-text": "name",
                          "item-value": "id",
                          class: "dropdown",
                          "model-value": _ctx.localFiles[index],
                          "onUpdate:modelValue": ($event: any) => (
                selectTab(_ctx.localFiles.findIndex((file) => file.id == $event))
              )
                        }, null, 8, ["options", "model-value", "onUpdate:modelValue"]),
                        _createVNode(_component_DropdownTrigger, {
                          "menu-indicator-position": "5px",
                          "menu-class": "menu-drag"
                        }, {
                          dropdownTrigger: _withCtx(() => [
                            _createVNode(_component_TextButton, {
                              icon: require('icons/more.svg'),
                              "is-show-text": false,
                              class: "more-btn"
                            }, null, 8, ["icon"])
                          ]),
                          dropdownContent: _withCtx(() => [
                            _createVNode(_component_TextButton, {
                              onOnClick: ($event: any) => (_ctx.addStamp(file, file.currentPage)),
                              text: _ctx.$t('template.vars.addStamp'),
                              icon: require('icons/add-stamp.svg'),
                              onDragstart: ($event: any) => (_ctx.dragStamp($event.dataTransfer, file)),
                              draggable: "true",
                              class: "drag-in-dropdown"
                            }, null, 8, ["onOnClick", "text", "icon", "onDragstart"]),
                            _createVNode(_component_TextButton, {
                              onOnClick: ($event: any) => (_ctx.addText(file, false, file.currentPage)),
                              text: _ctx.$t('template.vars.addText'),
                              icon: require('icons/add-text.svg'),
                              draggable: "true",
                              onDragstart: ($event: any) => (_ctx.dragText($event.dataTransfer, file)),
                              class: "drag-in-dropdown"
                            }, null, 8, ["onOnClick", "text", "icon", "onDragstart"]),
                            _createVNode(_component_TextButton, {
                              onOnClick: ($event: any) => (_ctx.addText(file, true, file.currentPage)),
                              text: _ctx.$t('template.vars.addCheckBox'),
                              icon: require('icons/add-checkbox.svg'),
                              draggable: "true",
                              onDragstart: ($event: any) => (_ctx.dragCheckbox($event.dataTransfer, file)),
                              class: "drag-in-dropdown"
                            }, null, 8, ["onOnClick", "text", "icon", "onDragstart"])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createVNode(_component_PdfPreviewVars, {
                        "file-path": file.path,
                        page: file.currentPage,
                        onOnReady: ($event: any) => (file.pageNums = $event),
                        onPageScaled: ($event: any) => (_ctx.onPageScaled(file, $event)),
                        onPageSized: 
              ({ width = 0, height = 0 }) => {
                file.width = width;
                file.height = height;
              }
            ,
                        class: "workspace"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DraggableContainer, {
                            onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
                            onDrop: _withModifiers(_ctx.handleDrag, ["prevent"])
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(file.inputs.filter(
                  (input) => input.page === file.currentPage
                ), (field) => {
                                return (_openBlock(), _createBlock(_component_TextInputDraggableResizable, {
                                  key: field.id,
                                  id: field.id,
                                  "is-stamp": field.isStamp,
                                  "is-optional-text": field.isOptionalText,
                                  "onUpdate:is-optional-text": ($event: any) => ((field.isOptionalText) = $event),
                                  x: field.x,
                                  "onUpdate:x": ($event: any) => ((field.x) = $event),
                                  y: field.y,
                                  "onUpdate:y": ($event: any) => ((field.y) = $event),
                                  h: field.h,
                                  "onUpdate:h": ($event: any) => ((field.h) = $event),
                                  w: field.w,
                                  "onUpdate:w": ($event: any) => ((field.w) = $event),
                                  text: field.text,
                                  "onUpdate:text": ($event: any) => ((field.text) = $event),
                                  "is-checked-by-default": field.checkedByDefault,
                                  "onUpdate:is-checked-by-default": ($event: any) => ((field.checkedByDefault) = $event),
                                  "is-checked": field.isChecked,
                                  "onUpdate:is-checked": ($event: any) => ((field.isChecked) = $event),
                                  signerID: field.signer,
                                  "onUpdate:signerID": ($event: any) => ((field.signer) = $event),
                                  "signer-order": field.contractSignerOrder,
                                  "onUpdate:signer-order": ($event: any) => ((field.contractSignerOrder) = $event),
                                  "font-size": field.fontSize,
                                  "onUpdate:font-size": ($event: any) => ((field.fontSize) = $event),
                                  "init-padding": field.padding,
                                  "min-height": field.minHeight,
                                  signers: _ctx.signers,
                                  type: field.type,
                                  scale: file.scale,
                                  "stamp-type": field.isRoundStamp,
                                  "canvas-width": (file.width ?? 0) * file.scale,
                                  "canvas-height": (file.height ?? 0) * file.scale,
                                  onOnRemoveItem: ($event: any) => (_ctx.onRemoveInput(file, field.id)),
                                  onOnCopyItem: ($event: any) => (_ctx.onCopyInput(file, field.id))
                                }, null, 8, ["id", "is-stamp", "is-optional-text", "onUpdate:is-optional-text", "x", "onUpdate:x", "y", "onUpdate:y", "h", "onUpdate:h", "w", "onUpdate:w", "text", "onUpdate:text", "is-checked-by-default", "onUpdate:is-checked-by-default", "is-checked", "onUpdate:is-checked", "signerID", "onUpdate:signerID", "signer-order", "onUpdate:signer-order", "font-size", "onUpdate:font-size", "init-padding", "min-height", "signers", "type", "scale", "stamp-type", "canvas-width", "canvas-height", "onOnRemoveItem", "onOnCopyItem"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["onDrop"])
                        ]),
                        _: 2
                      }, 1032, ["file-path", "page", "onOnReady", "onPageScaled", "onPageSized"]),
                      _createElementVNode("div", _hoisted_14, [
                        _withDirectives(_createElementVNode("input", {
                          type: "number",
                          class: "jump-page",
                          "onUpdate:modelValue": ($event: any) => ((file.currentPage) = $event)
                        }, null, 8, _hoisted_15), [
                          [_vModelText, file.currentPage]
                        ]),
                        _createElementVNode("p", _hoisted_16, "  " + _toDisplayString(`/ ${file.pageNums}`), 1),
                        _createVNode(_component_TextButton, {
                          class: "prev-page",
                          icon: require('icons/prev-arrow.svg'),
                          onOnClick: ($event: any) => (file.currentPage = _ctx.getPrevPage(file.currentPage)),
                          "disabled-icon": require('icons/prev-arrow-grey.svg'),
                          enabled: file.currentPage >= 2
                        }, null, 8, ["icon", "onOnClick", "disabled-icon", "enabled"]),
                        _createVNode(_component_TextButton, {
                          class: "next-page",
                          "right-icon": require('icons/next-arrow.svg'),
                          onOnClick: ($event: any) => (
                file.currentPage = _ctx.getNextPage(file.currentPage, file.pageNums)
              ),
                          "disabled-icon": require('icons/next-arrow-grey.svg'),
                          enabled: file.currentPage != file.pageNums
                        }, null, 8, ["right-icon", "onOnClick", "disabled-icon", "enabled"])
                      ])
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["tab-names"]))
    : _createCommentVNode("", true)
}